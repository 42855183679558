import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.scss";
import Header from "./common/header/Header";

class App extends Component {

  render() {
    return (
      <React.Fragment>
        <Router>
          <Header />
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
