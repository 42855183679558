import React, { Component } from "react";
import Product from "./product";
import Slides from "./slides";
import js from "../assets/images/logo.jpg";
import Footer from "../common/footer";
import AboutUs from "./aboutUs/AboutUs";
import "./Home.scss";

class Home extends Component {


  render() {
    return (
      <>
        <div className="jivan-sangini"> 
          <img src={js} alt="JS" width="100%" />
          <hr />
        </div>
        <Slides />
        <Product />
        <AboutUs />
        <Footer />
      </>
      )
  }
}

export default Home;
